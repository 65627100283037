const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'www.saudhussain.in',
  title: 'SH.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Saud Hussain',
  role: 'Full Stack Developer',
  description:
    'Specializing in building scalable, high-performance web applications with 2+ years of hands-on development experience across front-end and back-end technologies.',
  resume: '#',
  social: {
    linkedin: 'https://www.linkedin.com/in/saud-hussain/',
    github: 'https://github.com/saud-05',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: "Study Notion - EdTech Platform",
    description: "Developed an education platform enabling users to purchase courses and instructors to upload content. Integrated a payment gateway for seamless transactions.",
    stack: [],
    sourceCode: 'https://github.com/saud-05/Studynotion',
    livePreview: 'https://studynotion-pearl.vercel.app/',
  },
  {
    name:"SaudEstate - RealEstate Marketplace",
    description: "Developed a real estate marketplace application allowing users to browse, buy, and list properties. Integrated advanced search and filtering features for easy property discovery.",    
    stack: [],
    sourceCode: 'https://github.com/saud-05/saudestate',
    livePreview: 'https://saudestate-project55123.onrender.com/',
  },
  {
    name:"ByteBlog-Dynamic Blogging Platform",
    description:"Developed a blogging website where users can comment,like and engage with content. The platform includes an admin dashboard for managing user interactions.",
    stack: [],
    sourceCode: 'https://github.com/saud-05/blogging',
    livePreview: 'https://blogging-pq4i.onrender.com/',
  },
  // {
  //   name:"Zostara-Hostel Management System",
  //   description:"Developed a hostel management system enabling admins to manage rooms, bookings, and users. Integrated user authentication and role-based access for streamlined operations",
  //   stack: [],
  //   sourceCode: '#',
  //   livePreview: '#',
  // },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  "JavaScript", "TypeScript", "React.js","Rest API", "MongoDB",
  "Postgress", "MySQL", "Next.js", "NodeJS", "Redux toolkit", "Tailwind",
  "Git", "Shadcn", "Express", "Postman", "DBMS","MaterialUI","React Native"
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'Saudhusayn@mail.com',
}

export { header, about, projects, skills, contact }